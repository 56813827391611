:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
:root{
}
body{
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #52676B), color-stop(31%, #5A7276), to(#678287));
    background: -o-linear-gradient(top, #52676B 0, #5A7276 31%, #678287);
    background: linear-gradient(180deg, #52676B 0, #5A7276 31%, #678287);
}
footer.contentInfo{
    color: #FFFFFF;
    text-align: right;
}
h1{
    margin-bottom: 3rem;
}
h2.seminarMaterialsTitle{
}
h3.attendeeButtonSetTitle{
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    max-width: 300px;
    font-weight: bold;
}
header.banner .bannerContents > div:nth-child(2){
    -ms-flex-item-align: end;
        align-self: flex-end;
}
header.banner .bannerContents > div:nth-child(2){
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -ms-flex-item-align: end;
        align-self: flex-end;
}
.addAttendeeButtons .cartItemButton{
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.4rem;
    padding: 0.3rem 1rem 0.3rem 1rem;
    margin: 0.7rem 0.5rem 0 0;
    color: #FFFFFF;
    background-color: #3c7a84;
    border: 1px solid #3A676F;
}
.addAttendeeButtons .cartItemButton:hover{
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.4rem;
    padding: 0.3rem 1rem 0.3rem 1rem;
    margin: 0.7rem 0.5rem 0 0;
    color: #FFFFFF;
    background-color: #3c7a84;
    border: 1px solid #3A676F;
}
.addAttendeeButtons .cartItemButton:hover{
    background-color: #3A676F;
}
.addAttendeeButtons .cartItemButton img{
    display: inline-block;
    margin-right: 5px;
    vertical-align: baseline;
}
.addAttendeeButtons .formRowSplit{
    margin-bottom: 0;
}
.addAttendeesToCart{
    margin-bottom: 2rem;
}
.attendeeButtonSets{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
}
.attendeeButtonSets > div{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    margin-bottom: 2rem;
    padding: 1rem;
}
.attendeeFormRow .formRowSplit{
    margin-bottom: 0.4rem;
}
.attendeeFormRow .formRowSplit > div:first-child{
    width: 100px;
    min-width: 100px;
    max-width: 100px;
}
.attendeeFormRow .formRowSplit input[type=text],
.attendeeFormRow .formRowSplit .styledSelect{
    max-width: 300px;
    margin-right: 1rem;
}
.attendees{
    padding-top: 1rem;
}
.bannerContents{
    background-color: #0F1235;
}
.bannerContents{
    padding: 1rem 2rem 1rem 2rem;
}
.contactFormWrapper{
    max-width: 500px;
}
.copyright{
    font-size: 1.2rem;
}
.desktopMainMenu a{
    color: #FFFFFF;
    text-align: center;
    padding: 1rem;
}
.desktopMainMenu ul{
    background-color: #FFFFFF;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 1.2;
    border: 1px solid #BEBEBC;
    border-top: none;
    padding: 1rem 0 1rem 0;
}
.desktopMainMenu ul a:hover{
    background-color: #F4F4F2;
}
.desktopMainMenu ul li{
    position: relative;
}
.desktopMainMenu ul li a{
    color: #333333;
    text-align: left;
    padding: 0.4rem 2rem 0.4rem 2rem;
    position: relative;
    white-space: nowrap;
}
.fluidResort h2{
    font-weight: bold;
}
.fluidResort h2 a{
    text-decoration: none;
    color: inherit;
}
.fluidResort h3{
    margin-bottom: 2rem;
}
.footerContents{
    padding: 0.5rem 0.5rem 2rem 0.5rem;
}
.galleryCard img{
    padding: 0.5rem;
    border: 1px solid #D8D8D8;
    border-radius: 2px;
}
.galleryCard,
.galleryCard .cardCopy{
    padding: 1rem;
    line-height: 1.3;
}
.headerPhone{
    text-align: right;
    margin-bottom: 0.5rem;
}
.headerPhone a{
    color: #FFFFFF;
    font-size: 2.4rem;
    text-decoration: none;
}
.homeCopyBlock{
    margin: 3rem 0 2rem 0;
}
.homeCopyBlock h2{
    font-weight: 700;
    font-style: italic;
    font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
}
.indexNewsItem{
    padding: 1rem;
    background-color: #E2EBEC;
    border: 1px solid #CED7D8;
    border-radius: 3px;
}
.indexNewsItem .mediaObjectPrimary > div{
    padding: 2rem;
}
.indexResort{
    margin-bottom: 2rem;
}
.indexResort h3{
    font-weight: 600;
}
.indexResort:first-child{
    padding-bottom: 2rem;
    border-bottom: 1px solid #E3E3E3;
}
.listings > *{
    border-bottom: 1px solid #E8E8E8;
}
.listings > *{
    margin: 0 0 3rem 0;
    padding: 0 0 3rem 0;
}
.logo{
    display: inline-block;
    max-width: 80%;
}
.seminarMaterialsHeader{
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #DEDEDC;
}
.seminarMaterialsGroup{
    padding: 0 1rem 2rem 1rem;
}
.seminarMaterialsWrapper{
    padding: 0 1rem 2rem 1rem;
}
.mediaObject.resortMediaObject .mediaObjectAsideLeft,
.mediaObject.resortMediaObject .mediaObjectAsideRight{
    -webkit-box-flex: 0;
        -ms-flex: 0 0 40%;
            flex: 0 0 40%;
    padding-bottom: 2rem;
}
.registrationResort{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row;
            flex-flow: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    position: relative;
    width: 100%;
    padding: 0 0 3rem 0;
}
.registrationResort > *{
    width: 100%;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.registrationResortAsideLeft{
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
}
.registrationResortAsideLeft,
.registrationResortAsideRight{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 30%;
            flex: 1 1 30%;
    max-width: 22rem;
    min-width: 12rem;
}
.registrationResortAsideLeft:empty,
.registrationResortAsideRight:empty{
    display: none;
}
.registrationResortAsideRight{
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
}
.registrationResortPrimary{
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    padding: 0 1rem 0 1rem;
}
.registrationResortPrimary ~ .registrationResortAsideLeft{
    padding-bottom: 0;
}
.registrationResortPrimary ~ .registrationResortAsideRight{
    padding-bottom: 0;
}
.resortLink > object{
    margin-left: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: baseline;
    display: inline-block;
}
.resortMediaObject{
    margin-bottom: 3rem;
}
.resource h4,
.seminarMaterial h4{
    font-weight: 600;
}
.resource,
.seminarMaterial{
    padding: 1rem;
}
.resourceDescription,
.seminarMaterialDescription{
    padding: 0 1rem 0 1rem;
}
.sessionDate{
    font-size: 1.8rem;
    color: #253572;
    font-weight: 600;
}
.sessionDescription{
    padding: 0 1rem 0 1rem;
}
.sessionFaculty{
    font-weight: 600;
}
.sessionTime{
    font-style: italic;
    font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
}
.sidebarButtonGroup{
    text-align: center;
    margin-top: 3rem;
}
.sidebarButtonGroup .button{
    margin: 0 auto 1rem auto;
}
.slidesDots{
    position: relative;
    padding-top: 0.4rem;
    bottom: auto;
}
.staffPhoto{
    border: 1px solid #E3E3E3;
}
.testimonialSource{
    font-style: italic;
    padding-left: 2rem;
}
.testimonialTestimonial{
    font-size: 1.8rem;
    position: relative;
    line-height: 1.4;
    margin-bottom: 0.8rem;
}
.testimonialTestimonial p:first-child:before{
    content: "\201C";
    display: inline-block;
    vertical-align: text-bottom;
    padding-right: 0.3rem;
}
.testimonialTestimonial p:last-child:after{
    content: "\201D";
    display: inline-block;
    vertical-align: text-bottom;
    padding-left: 0.3rem;
}
.utilityNav{
    color: #FFFFFF;
}
.utilityNav{
    text-align: right;
}
.utilityNav a{
    color: #FFFFFF;
}
.utilityNav object{
    vertical-align: baseline;
    margin-left: 0.5rem;
    margin-top: 0.2rem;
}
#desktopNavigation{
    background-color: #3F466B;
}
#index .slidesWrapper{
    margin: 1%;
}
#mainContent section .constrainedWidth{
    background-color: #FFFFFF;
}
#paymentForm,
.registrationFormWrapper,
.contactFormWrapper{
    margin: 3rem 0 3rem 0;
}
.copyright p{
    display: inline;
}


:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
:root{
}
@media only screen and (min-width: 500px){
    .mediaObject.resortMediaObject .mediaObjectAsideLeft{
        padding: 0 2rem 0 0;
    }
    .mediaObject.resortMediaObject .mediaObjectAsideRight{
        padding: 0 0 0 2rem;
    }
    .registrationResortPrimary{
        padding: 0 1rem 0 3rem;
    }
}
@media only screen and (min-width: 650px){
    .addAttendeeButtons .formRowSplit > :first-child{
        max-width: 120px;
        min-width: 100px;
    }
    .formRowSplit > *:first-child{
        max-width: 270px;
    }
}
@media only screen and (min-width: 760px){
    .mediaObject.resortMediaObject .mediaObjectAsideLeft{
        padding: 0 3rem 0 0;
    }
    .mediaObject.resortMediaObject .mediaObjectAsideRight{
        padding: 0 0 0 3rem;
    }
    .registrationResorts{
        -js-display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }
    .registrationResorts > div{
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        width: calc(50% - 3rem);
        max-width: calc(50% - 3rem);
    }
}
@media only screen and (min-width: 800px){
    .attendeeButtonSets{
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row;
                flex-flow: row;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
    }
    .attendeeButtonSets > div{
        width: 50%;
        max-width: 50%;
    }
}
@media only screen and (min-width: 1050px){

}


:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
:root{
}
h2.seminarMaterialsTitle{
    color: #333333;
    font-family: 'Source Sans Pro', Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 2.6rem;
    font-weight: normal;
    margin-top: 5rem;
    margin-bottom: 3rem;
}
@media screen and (min-width: 400px){
    h2.seminarMaterialsTitle{
        font-size: calc(2.6rem + (((3 - 2.6) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
}
@media screen and (min-width: 1050px){
    h2.seminarMaterialsTitle{
        font-size: 3rem;
    }
}
