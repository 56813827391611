:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
:root{
}
.buttonsWrapper .button + .button,
.buttonsWrapper .buttonSmall + .buttonSmall,
.buttonsWrapper .button + .buttonSmall,
.buttonsWrapper .buttonSmall + .button{
    margin-top: 1.5rem;
}
.button{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #3a5793;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0.8rem 2rem 0.8rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
    border-radius: 3px;
}
.button:hover{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #3a5793;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0.8rem 2rem 0.8rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
    border-radius: 3px;
}
button.button{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #3a5793;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0.8rem 2rem 0.8rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
    border-radius: 3px;
}
input[type="submit"].button{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #3a5793;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0.8rem 2rem 0.8rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
    border-radius: 3px;
}
#cform input[type="submit"].button{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #3a5793;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0.8rem 2rem 0.8rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
    border-radius: 3px;
}
button:hover.button{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #3a5793;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0.8rem 2rem 0.8rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
    border-radius: 3px;
}
input[type="submit"]:hover.button{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #3a5793;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0.8rem 2rem 0.8rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
    border-radius: 3px;
}
#cform input[type="submit"]:hover.button{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #3a5793;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0.8rem 2rem 0.8rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
    border-radius: 3px;
}
.buttonSmall{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #3a5793;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0.8rem 2rem 0.8rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
    border-radius: 3px;
}
.buttonSmall:hover{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #3a5793;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0.8rem 2rem 0.8rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
    border-radius: 3px;
}
button.buttonSmall{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #3a5793;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0.8rem 2rem 0.8rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
    border-radius: 3px;
}
input[type="submit"].buttonSmall{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #3a5793;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0.8rem 2rem 0.8rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
    border-radius: 3px;
}
#cform input[type="submit"].buttonSmall{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #3a5793;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0.8rem 2rem 0.8rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
    border-radius: 3px;
}
button:hover.buttonSmall{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #3a5793;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0.8rem 2rem 0.8rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
    border-radius: 3px;
}
input[type="submit"]:hover.buttonSmall{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #3a5793;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0.8rem 2rem 0.8rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
    border-radius: 3px;
}
#cform input[type="button"]:hover.buttonSmall{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #3a5793;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0.8rem 2rem 0.8rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
    border-radius: 3px;
}
.button > *,
button.button > *,
input[type="submit"].button > *,
#cform input[type="submit"].button > *,
.buttonSmall > *,
button.buttonSmall > *,
input[type="submit"].buttonSmall > *,
#cform input[type="submit"].buttonSmall > *{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.button i,
button.button i,
input[type="submit"].button i,
#cform input[type="submit"].button i,
.buttonSmall i,
button.buttonSmall i,
input[type="submit"].buttonSmall i,
#cform input[type="submit"].buttonSmall i{
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
}
.button:hover{
    background-color: #334D82;
}
.buttonSmall:hover{
    background-color: #334D82;
}
button.button:hover{
    background-color: #334D82;
}
input[type="submit"].button:hover{
    background-color: #334D82;
}
#cform input[type="submit"].button:hover{
    background-color: #334D82;
}
button.buttonSmall:hover{
    background-color: #334D82;
}
input[type="submit"].buttonSmall:hover{
    background-color: #334D82;
}
#cform input[type="submit"].buttonSmall:hover{
    background-color: #334D82;
}
.buttonSmall,
.buttonSmall:hover,
button.buttonSmall,
input[type="submit"].buttonSmall,
#cform input[type="submit"].buttonSmall,
button.buttonSmall:hover,
input[type="submit"].buttonSmall:hover,
#cform input[type="submit"].buttonSmall:hover{
    font-size: 90%;
    padding: 0.8rem 1.3rem 0.8rem 1.3rem;
}
.button object,
.buttonSmall object{
    margin-right: 1rem;
    vertical-align: baseline;
}
button.button[disabled]{
    background-color: #3a5793;
    cursor: not-allowed;
    color: #FFFFFF;
    opacity: 0.2;
}
button.buttonSmall[disabled]{
    background-color: #3a5793;
    cursor: not-allowed;
    color: #FFFFFF;
    opacity: 0.2;
}
button.button[disabled]:hover{
    background-color: #3a5793;
    cursor: not-allowed;
    color: #FFFFFF;
    opacity: 0.2;
}
button.buttonSmall[disabled]:hover{
    background-color: #3a5793;
    cursor: not-allowed;
    color: #FFFFFF;
    opacity: 0.2;
}
@media screen and (min-width: 400px){
    .button{
        font-size: calc(1.4rem + (((1.4 - 1.3) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
    .button:hover{
        font-size: calc(1.4rem + (((1.4 - 1.3) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
    button.button{
        font-size: calc(1.4rem + (((1.4 - 1.3) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
    input[type="submit"].button{
        font-size: calc(1.4rem + (((1.4 - 1.3) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
    #cform input[type="submit"].button{
        font-size: calc(1.4rem + (((1.4 - 1.3) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
    button.button:hover{
        font-size: calc(1.4rem + (((1.4 - 1.3) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
    input[type="submit"].button:hover{
        font-size: calc(1.4rem + (((1.4 - 1.3) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
    #cform input[type="submit"].button:hover{
        font-size: calc(1.4rem + (((1.4 - 1.3) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
}
@media screen and (min-width: 1050px){
    .button{
        font-size: 1.4rem;
    }
    .button:hover{
        font-size: 1.4rem;
    }
    button.button{
        font-size: 1.4rem;
    }
    input[type="submit"].button{
        font-size: 1.4rem;
    }
    #cform input[type="submit"].button{
        font-size: 1.4rem;
    }
    button.button:hover{
        font-size: 1.4rem;
    }
    input[type="submit"].button:hover{
        font-size: 1.4rem;
    }
    #cform input[type="submit"].button:hover{
        font-size: 1.4rem;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
.cardsWrapper{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-width: 100%;
    position: relative;
    width: 100%;
}
.cardsWrapper > div{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    margin: 0 0 2rem 0;
    width: 100%;
    max-width: calc(40rem - 2rem);
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
    text-decoration: none;
}
.cardsWrapper > a{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    margin: 0 0 2rem 0;
    width: 100%;
    max-width: calc(40rem - 2rem);
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
    text-decoration: none;
}
.cardsWrapper > div.fillerCard.fillerCard{
    margin-bottom: 0;
    padding: 0;
    visibility: hidden;
}
.cardsWrapper > div.fillerCard *{
    display: none;
}
.cardCopy{
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    position: relative;
}
.cardFlexFooter{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.cardFlexFooter > div{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
            flex: 1 1 50%;
    width: 50%;
}
.cardFooter{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.cardFooter > div{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.cardFooter,
.cardFlexFooter{
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    position: relative;
    text-align: center;
}
.cardHeader{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: relative;
}
.cardHeader > div{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.cardImage{
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    position: relative;
}
@media only screen and (min-width: 450px){
    .cardsWrapper{
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }
    .cardsWrapper > div{
        max-width: calc(50% - (2rem - (2rem / 2)));
    }
    .cardsWrapper > a{
        max-width: calc(50% - (2rem - (2rem / 2)));
    }
}
@media only screen and (min-width: 600px){
    .cardsWrapper > div{
        max-width: calc(33.333% - (2rem - (2rem / 3)));
    }
    .cardsWrapper > a{
        max-width: calc(33.333% - (2rem - (2rem / 3)));
    }
}
@media only screen and (min-width: 1050px){
    .cardsWrapper > div{
        max-width: calc(25% - (2rem - (2rem / 4)));
    }
    .cardsWrapper > a{
        max-width: calc(25% - (2rem - (2rem / 4)));
    }
}



:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
.cartCancelUpdate{
    right: -170px;
    top: 0;
}
.cartColumn{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 100%;
    padding: 0 1rem 0.5rem 1rem;
    position: relative;
}
.cartDeleteItemCheckbox{
    visibility: hidden;
}
.cartEditRemove{
    right: 0;
    top: 0;
}
.cartEditRemove,
.cartCancelUpdate{
    position: absolute;
    width: 160px;
}
.cartFieldset{
    margin: 0;
}
.cartFieldset .cartFieldGroup{
    max-width: 500px;
}
.cartFieldset legend{
    margin-bottom: 2.5rem;
}
.cartFieldset legend{
    color: #333333;
    font-size: 2rem;
}
.cartFieldset legend span{
    display: inline-block;
    font-size: 70%;
    font-weight: normal;
    padding: 0 1rem 0 1rem;
    vertical-align: text-bottom;
}
.cartFieldset legend span a{
    display: inline-block;
    white-space: nowrap;
}
.cartFieldsetWrapper{
    margin: 2rem;
    padding: 1rem;
    background-color: #FCFCFC;
    border: 1px solid #EFEFEF;
    border-radius: 3px;
}
.cartHeader{
    display: none;
    border-bottom: 2px solid #888886;
    clear: both;
}
.cartHeader{
    border-bottom: 1px solid #B3B3B1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.5rem;
    padding: 0;
}
.cartRow{
    border-bottom: 1px solid #B3B3B1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.5rem;
    padding: 0;
}
.cartRow .cartItemActions{
    overflow: hidden;
    min-height: 30px;
}
.cartItemActions,
.cartItemTotal,
.cartItemQty{
    text-align: right;
}
.cartItemButton > *{
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    padding: 0.2rem;
}
.cartItemButton object{
    margin-right: 0.5rem;
    vertical-align: baseline;
}
.cartItemButton{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: transparent;
    border: 1px solid #A9A9A7;
    border-radius: 3px;
    clear: both;
    color: #333333;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0 0.5rem 0 0.5rem;
    position: relative;
    text-decoration: none;
    background-image: none;
    font-size: 1.2rem;
    margin: 0 0 0 0.5rem;
    vertical-align: middle;
}
.cartItemButton:hover{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: transparent;
    border: 1px solid #A9A9A7;
    border-radius: 3px;
    clear: both;
    color: #333333;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0 0.5rem 0 0.5rem;
    position: relative;
    text-decoration: none;
    background-image: none;
    font-size: 1.2rem;
    margin: 0 0 0 0.5rem;
    vertical-align: middle;
}
.cartItemButton:hover{
    background-color: #EFEFEF;
}
.cartItemDetails{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.cartItemDetails .cartItemImage{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
            flex: 1 1 50%;
    max-width: 200px;
    min-width: 150px;
}
.cartItemDetails > div{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 100%;
    position: relative;
}
.cartItemEditable input[type=text][disabled],
.cartItemEditable textarea[disabled]{
    background-color: transparent;
    border-color: transparent;
}
.cartItemEditable input[type=text]{
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    border: 1px solid #C9C9C7;
    max-height: 3rem;
}
.cartItemEditable .styledSelect{
    border: 1px solid #C9C9C7;
}
.cartItemEditable .styledSelect::after{
    background-color: #7E7E7C;
    font-size: 1rem;
    padding-top: 0.4rem;
}
.cartItemEditable.disabled .styledSelect::after{
    display: none;
}
.cartItemEditable.disabled .styledSelect,
.cartItemEditable.disabled .styledSelect select{
    background-color: transparent;
    border-color: transparent;
}
.cartItemEditable textarea{
    resize: none;
    overflow-y: auto;
    padding: 0.2rem 1rem 0.2rem 1rem;
    border: 1px solid #C9C9C7;
}
.cartItemName{
    font-weight: 600;
}
.cartItemDescription,
.cartItemOptions{
    padding: 1rem 1rem 1rem 2rem;
}
.cartItemOptionRow{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    position: relative;
    width: 100%;
    padding-bottom: 0.3rem;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.cartItemOptionRow > div{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.cartItemOptionRow > div:first-child{
    width: 20%;
    min-width: 100px;
    max-width: 150px;
    text-align: right;
    padding-right: 1rem;
}
.cartItemQty input[type=text]{
    max-width: 80px;
    text-align: right;
}
.cartMobileRightAlign > span:last-child,
.cartTotalsRow > span:last-child{
    width: 100%;
    display: inline-block;
    padding: 0 0 0 1rem;
    max-width: 100px;
}
.myCartMenuButton object{
    margin: 0 0.7rem 0 0.7rem;
}
.cartPageButtons{
    margin: 2rem;
    text-align: right;
}
.cartRow{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #DEDEDC;
}
.cartTotalsRow{
    text-align: right;
    font-size: 2rem;
}
.cartTotalsRow > span:first-child{
    font-weight: bold;
}
.emptyCartWarning{
    color: #E64F0C;
    font-size: 2.2rem;
    padding: 1.5rem;
}
.mobileCartLabel{
    color: #333333;
    display: inline-block;
    font-weight: normal;
    padding-right: 0.5rem;
    text-align: right;
}
.updateCartFormRow:last-of-type .cartRow{
    border-bottom: 2px solid #888886;
}
.btnDel{
    display: inline-block;
    border: none;
    cursor: pointer;
    background-color: transparent;
}
.attendeesHeaderLabelsWrapper{
    display: none;
}
.attendeesHeaderLabels,
.addSomeAttendeesHeader{
    border-bottom: 1px solid #333333;
    margin-bottom: 1rem;
    font-size: 1.8rem;
}
.attendeeFormRow{
    border-bottom: 1px solid #DDDDDD;
    margin-bottom: 1rem;
}
.attendeesHeaderLabels,
.attendeeFormRow{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.addSomeAttendeesHeader,
.attendeesHeaderLabels > div{
    font-weight: bold;
}
.addSomeAttendeesHeader,
.attendeesHeaderLabels > div,
.attendeeFormRow > div{
    padding: 1rem;
}
.attendeesHeaderLabels > div:nth-child(1),
.attendeeFormRow > div:nth-child(1){
    -webkit-box-flex: 1;
        -ms-flex: 1 1 65%;
            flex: 1 1 65%;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
}
.attendeesHeaderLabels > div:nth-child(2),
.attendeeFormRow > div:nth-child(2){
    -webkit-box-flex: 1;
        -ms-flex: 1 1 30%;
            flex: 1 1 30%;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
}
.attendeesHeaderLabels > div:nth-child(3),
.attendeeFormRow > div:nth-child(3){
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50px;
            flex: 0 0 50px;
    text-align: center;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
}
.attendeeRowLabel{
    margin: 0 0 0.5rem 0;
}
.yourInfoFormAddresses{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
}
.yourInfoFormAddresses > div{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 100%;
}
.reviewYourInformation > div{
    width: 100%;
    margin-bottom: 2rem;
}
.reviewYourInformation > div > h4{
    font-size: 1.5rem;
    font-weight: bold;
    border-bottom: 1px solid #BEBEBC;
}
.clearCartButton{
    background-color: #FFFFFF;
    border: 1px solid #838383;
    border-radius: 3px;
    color: #676767;
    display: block;
    float: right;
    font-size: 1.3rem;
    padding: 0.4rem 0.8rem 0.4rem 0.8rem;
    text-decoration: none;
    margin-bottom: 2rem;
}
.clearCartButton:hover{
    background-color: #B12222;
    border: 1px solid #951D1D;
    color: #FFFFFF;
}
.printableOrderDetails{
    display: none;
}
@media only screen and (min-width: 650px){
    .attendeesHeaderLabels,
    .attendeeFormRow{
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    }
    .attendeesHeaderLabels > div:nth-child(1),
    .attendeeFormRow > div:nth-child(1){
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1;
    }
    .attendeesHeaderLabels > div:nth-child(2),
    .attendeeFormRow > div:nth-child(2){
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
    }
    .attendeesHeaderLabels > div:nth-child(3),
    .attendeeFormRow > div:nth-child(3){
        -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
                order: 3;
    }
}
@media only screen and (min-width: 800px){
    .cartColumn{
        width: auto;
    }
    .cartHeader .cartColumn{
        white-space: nowrap;
    }
    .cartHeader,
    .cartRow{
        -js-display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
    }
    .cartItemActions{
        -webkit-box-flex: 0;
            -ms-flex: 0 0 180px;
                flex: 0 0 180px;
    }
    .cartItemTotal{
        -webkit-box-flex: 0;
            -ms-flex: 0 0 110px;
                flex: 0 0 110px;
    }
    .cartItemQty{
        -webkit-box-flex: 0;
            -ms-flex: 0 0 80px;
                flex: 0 0 80px;
    }
    .mobileCartLabel{
        display: none;
    }
    .reviewYourInformation{
        -js-display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
    }
    .reviewYourInformation > div{
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        margin: 1rem;
        width: 33.333%;
        max-width: 33.333%;
    }
    .attendeesHeaderLabelsWrapper{
        display: block;
    }
    .yourInfoFormAddresses{
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    }
    .yourInfoFormAddresses > div{
        max-width: 50%;
    }
}


:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
.arrowedCartBreadcrumbs{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: 0 0 3.0rem 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}
.arrowedCartBreadcrumbs li{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: rgb(163, 184, 220);
    color: #ffffff;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: 1px;
    padding: 0;
    position: relative;
    width: 100%;
}
.arrowedCartBreadcrumbs li > *{
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1.2;
    padding: 0.5rem 1.0rem 0.5rem 1.0rem;
    position: relative;
    text-align: left;
    text-decoration: none;
    width: 100%;
}
.arrowedCartBreadcrumbs li a{
    color: #ffffff;
}
.arrowedCartBreadcrumbs li.current{
    background-color: rgb(89, 126, 192);
    color: #ffffff;
}
.arrowedCartBreadcrumbs li.current a{
    color: #ffffff;
}
.arrowedCartBreadcrumbs li.seen{
    background-color: #4169ad;
    color: #ffffff;
}
.arrowedCartBreadcrumbs li.seen a{
    color: #ffffff;
}
.arrowedCartBreadcrumbs li:hover.seen{
    background-color: rgb(89, 126, 192);
    color: #ffffff;
}
.arrowedCartBreadcrumbs li:hover.seen a{
    color: #ffffff;
}
@media only screen and (min-width: 400px){
    .arrowedCartBreadcrumbs li{
        max-width: calc(50% - 4px);
    }
}
@media only screen and (min-width: 550px){
    .arrowedCartBreadcrumbs li{
        width: 20%;
    }
    .arrowedCartBreadcrumbs li > *{
        text-align: center;
    }
}
@media only screen and (min-width: 750px){
    .arrowedCartBreadcrumbs li{
        margin: 0;
    }
    .arrowedCartBreadcrumbs li > *{
        font-size: 1.6rem;
    }
    .arrowedCartBreadcrumbs li.current:nth-last-child(n+2) > *::after{
        border-bottom: 40px solid transparent;
        border-left: 19px solid rgb(89, 126, 192);
        border-top: 40px solid transparent;
    }
    .arrowedCartBreadcrumbs li.current:nth-last-child(n+2) > *::before{
        border-bottom: 40px solid transparent;
        border-left: 19px solid #FFFFFF;
        border-top: 40px solid transparent;
    }
    .arrowedCartBreadcrumbs li.seen:hover:nth-last-child(n+2) > a::after{
        border-left: 19px solid rgb(89, 126, 192);
    }
    .arrowedCartBreadcrumbs li.seen:nth-last-child(n+2) > *::after{
        border-bottom: 40px solid transparent;
        border-left: 19px solid #4169ad;
        border-top: 40px solid transparent;
    }
    .arrowedCartBreadcrumbs li.seen:nth-last-child(n+2) > *::before{
        border-bottom: 40px solid transparent;
        border-left: 19px solid #FFFFFF;
        border-top: 40px solid transparent;
    }
    .arrowedCartBreadcrumbs li:nth-last-child(n+2) > *::after{
        content: "";
        display: block;
        height: 0;
        left: calc(100% - 14px);
        margin-top: -40px;
        position: absolute;
        top: 50%;
        width: 0;
        z-index: 2;
    }
    .arrowedCartBreadcrumbs li:nth-last-child(n+2) > *::after{
        border-bottom: 40px solid transparent;
        border-left: 19px solid rgb(163, 184, 220);
        border-top: 40px solid transparent;
    }
    .arrowedCartBreadcrumbs li:nth-last-child(n+2) > *::before{
        content: "";
        display: block;
        height: 0;
        left: calc(100% - 14px);
        margin-left: 2px;
        margin-top: -40px;
        position: absolute;
        top: 50%;
        width: 0;
        z-index: 1;
    }
    .arrowedCartBreadcrumbs li:nth-last-child(n+2) > *::before{
        border-bottom: 40px solid transparent;
        border-left: 19px solid #FFFFFF;
        border-top: 40px solid transparent;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
.confirmBoxButton{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #D4D4D2;
    border: none;
    border-radius: 4px;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    margin: 0.5rem;
    padding: 1.5rem 3.0rem 1.5rem 3.0rem;
    position: relative;
    text-align: center;
    text-decoration: none;
}
.confirmBoxButton *{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
#confirmBox{
    background-color: #FFFFFF;
    border-radius: 5px;
    left: 50%;
    line-height: 1.4;
    max-width: 300px;
    overflow: hidden;
    position: fixed;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 90%;
    z-index: 10001;
}
#confirmBox .confirmBoxText{
    padding-bottom: 1.5rem;
}
#confirmBox .confirmBoxTextWrapper,
#confirmBox .confirmBoxTitle{
    padding: 1.5rem;
}
#confirmBox .confirmBoxTitle{
    background-color: #D4D4D2;
    color: #FFFFFF;
    font-weight: bold;
    letter-spacing: 1px;
}
#confirmBox .confirmButtons{
    text-align: center;
}
#confirmOverlay{
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
.cookieConsentFormWrapper{
    margin: 2rem 0 2rem 0;
}
.cookieConsentFormWrapper .consentDescription{
    padding: 1rem 2rem 0 2rem;
}
.cookieConsentFormWrapper .consentDescription > div:last-child{
    padding: 1rem 1rem 0 1rem;
}
.cookieConsentFormWrapper .consentType{
    padding: 2rem;
    border-radius: 3px;
    border: 1px solid #BBBBBB;
    margin-bottom: 1rem;
}
.cookieConsentFormWrapper div:last-child{
    padding: 1rem;
}
.cookieConsentFormWrapper h3{
    color: #3a5793;
    border-bottom: 1px solid #3a5793;
}
.cookieNotice{
    color: #ffffff;
    background-color: #464848;
    position: relative;
    padding: 1rem;
    font-size: 1.4rem;
    line-height: 1.4;
}
.cookieNotice label{
    margin-right: 2rem;
}
.cookieNotice button.buttonSmall{
    color: #ffffff;
    background-color: #8d8d8d;
    border-radius: 3px;
    border: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 1.3rem;
    margin: 0.3rem;
    font-weight: bold;
}
.cookieNotice button.buttonSmall:hover{
    color: #ffffff;
    background-color: #8d8d8d;
    border-radius: 3px;
    border: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 1.3rem;
    margin: 0.3rem;
    font-weight: bold;
}
.cookieNotice button.buttonSmall:focus{
    color: #ffffff;
    background-color: #8d8d8d;
    border-radius: 3px;
    border: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 1.3rem;
    margin: 0.3rem;
    font-weight: bold;
}
.cookieNotice button.buttonSmall:hover{
    background-color: #7D7D7D;
    color: #ffffff;
}
.cookieNotice button.buttonSmall:focus{
    background-color: #7D7D7D;
    color: #ffffff;
}
.cookieNotice > div{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.cookieNotice > div > div{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    padding: 0.7rem 2rem 0.7rem 2rem;
}
.cookieNotice > div > div:last-child{
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
}
.cookieNoticeWrapper{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 1000;
    background-color: transparent;
}
.cookieNoticeWrapper a{
    color: inherit;
}
.cookieSettingsToggle > span{
    font-size: 1.2rem;
    padding: 0.3rem 1rem 0.3rem 1rem;
    color: #ffffff;
    background-color: #464848;
    display: inline-block;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-right: 3rem;
    float: right;
}
.cookieSettingsToggle a{
    text-decoration: none;
}
.consentCheckboxes label{
    white-space: nowrap;
}
.consentCheckboxes{
    padding: 2rem;
}
@media only screen and (min-width: 800px){
    .cookieNotice > div > div:last-child{
        white-space: nowrap;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
figure{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    overflow: hidden;
}
figure img,
figure object{
    display: block;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    max-height: 100%;
    margin: auto;
}
figure.objectFitImage{
    height: 100%;
    position: relative;
    background-size: cover;
    background-position: center center;
}
.ratioWrapper figure.objectFitImage{
    height: auto;
    position: absolute;
}
figure.objectFitImage img{
    height: 100vh;
    position: absolute;
    width: 100vw;
    -o-object-fit: cover;
       object-fit: cover;
}
.contentCaptionedfigureLeft,
.contentCaptionedfigureRight,
.contentCaptionedfigureCentered{
    display: block;
    margin: 2rem auto 2rem auto;
    max-width: 400px;
}
.contentCaptionedfigureLeft figcaption,
.contentCaptionedfigureRight figcaption,
.contentCaptionedfigureCentered figcaption{
    font-style: italic;
    font-size: 90%;
    padding: 0;
}
.contentCaptionedfigureLeft figcaption p,
.contentCaptionedfigureRight figcaption p,
.contentCaptionedfigureCentered figcaption p{
    padding: 0;
    margin: 0;
}
.contentCaptionedfigureLeft img + figcaption,
.contentCaptionedfigureRight img + figcaption,
.contentCaptionedfigureCentered img + figcaption{
    margin-top: 1rem;
}
img {
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
img[data-src] {
  opacity: 0;
}
/*img[src$=".svg"] {
    width: 100%;
}*/
@media only screen and (min-width: 600px){
    .contentCaptionedfigureLeft,
    .contentCaptionedfigureRight{
        max-width: 300px;
    }
    .contentCaptionedfigureLeft{
        float: left;
        margin: 0 3rem 3rem 0;
    }
    .contentCaptionedfigureRight{
        float: right;
        margin: 0 0 3rem 3rem;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
input{
    color: #333333;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}
select{
    color: #333333;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}
textarea{
    color: #333333;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}
optgroup{
    color: #333333;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}
option{
    color: #333333;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}
input[disabled],
select[disabled],
textarea[disabled]{
    cursor: not-allowed;
}
input[type="submit"],
input[type="reset"],
input[type="file"]::-webkit-file-upload-button,
button{
    border-radius: 0;
}
input[type="radio"],
input[type="checkbox"]{
    display: inline-block;
    margin: 0 0.3rem 0 0;
}
input[type="text"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    width: 100%;
}
input[type="date"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    width: 100%;
}
input[type="range"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    width: 100%;
}
input[type="email"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    width: 100%;
}
input[type="url"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    width: 100%;
}
input[type="password"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    width: 100%;
}
input[type="tel"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    width: 100%;
}
input[type="number"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    width: 100%;
}
input[type="month"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    width: 100%;
}
textarea{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    width: 100%;
}
.styledSelect{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    width: 100%;
}
select{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    margin-bottom: 0.5rem;
}
.styledSelect{
    background-color: #FFFFFF;
    padding: 0;
    position: relative;
}
.styledSelect select{
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
    min-width: 100%;
    padding: 0.2rem 24px 0.2rem 0.5rem;
}
.styledSelect select option{
    background-color: #FEFEFE;
}
.styledSelect::after{
    background-color: #B3B3B1;
    color: #FFFFFF;
    content: "\f078";
    display: block;
    font-family: "FontAwesome", sans-serif;
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 24px;
    z-index: 0;
}
.checkRadio label{
    margin-left: 2rem;
    cursor: pointer;
}
.checkRadio label:first-of-type{
    margin-left: 0;
}
.formRowFull,
.formRowSplit{
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
}
.formRowSplit{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
}
.formRowFull > *{
    margin-bottom: 0.5rem;
    width: 100%;
}
.formRowSplit > *{
    margin-bottom: 0.5rem;
    width: 100%;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    position: relative;
}
.validationError{
    color: #E64F0C;
}
.validationError::before{
    background-color: #E64F0C;
    border-radius: 50%;
    color: #FFFFFF;
    content: "!";
    display: inline-block;
    height: 24px;
    line-height: 2.4rem;
    margin-right: 0.5rem;
    text-align: center;
    width: 24px;
}
.required::before{
    content: "*\a0";
}
@media only screen and (min-width: 650px){
    .formRowSplit{
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
    }
    .formRowSplit > *:first-child{
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        max-width: 200px;
        min-width: 150px;
        padding: 0 1.5rem 0 0;
        -ms-flex-item-align: center;
            -ms-grid-row-align: center;
            align-self: center;
        text-align: right;
    }
    .formRowFull > *:last-child,
    .formRowSplit > *{
        margin-bottom: 0;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
.listings{
    margin: 0;
}
.listings > *{
    margin: 0 0 1.5rem 0;
    padding: 0 0 1.5rem 0;
}
.listings > *:last-child{
    border-bottom: none;
}
.listings > a{
    display: block;
    text-decoration: none;
}


:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
.mediaObject{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    position: relative;
    width: 100%;
}
.mediaObject > *{
    padding-bottom: 3rem;
    width: 100%;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.mediaObject .mediaObjectPrimary{
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
}
.mediaObject .mediaObjectAsideLeft{
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
}
.mediaObject .mediaObjectAsideRight{
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
}
.mediaObject .mediaObjectAsideLeft:empty,
.mediaObject .mediaObjectAsideRight:empty{
    display: none;
}
@media only screen and (min-width: 500px){
    .mediaObject{
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row;
                flex-flow: row;
    }
    .mediaObject .mediaObjectPrimary{
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
        padding: 0;
    }
    .mediaObject .mediaObjectPrimary ~ .mediaObjectAsideLeft{
        padding-bottom: 0;
        /*padding-right: var(--media-object-spacing);*/
    }
    .mediaObject .mediaObjectPrimary ~ .mediaObjectAsideRight{
        padding-bottom: 0;
        /*padding-left: var(--media-object-spacing);*/
    }
    .mediaObject .mediaObjectAsideLeft{
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1;
    }
    .mediaObject .mediaObjectAsideLeft{
        -webkit-box-flex: 0;
            -ms-flex: 0 0 25%;
                flex: 0 0 25%;
        max-width: 43rem;
        min-width: 20rem;
    }
    .mediaObject .mediaObjectAsideRight{
        -webkit-box-flex: 0;
            -ms-flex: 0 0 25%;
                flex: 0 0 25%;
        max-width: 43rem;
        min-width: 20rem;
    }
    .mediaObject .mediaObjectAsideRight{
        -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
                order: 3;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
:root{
}
.confirmBoxButton{
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #B3B3B1;
    border: none;
    border-radius: 4px;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    margin: 0.5rem;
    padding: 1.5rem 3.0rem 1.5rem 3.0rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.4rem;
}
.confirmBoxButton:hover{
    background-color: #7E7E7C;
}
.confirmBoxButton:focus{
    background-color: #7E7E7C;
}
.confirmBoxButton *{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
#confirmBox{
    background-color: #FFFFFF;
    border-radius: 5px;
    left: 50%;
    line-height: 1.4;
    max-width: 300px;
    overflow: hidden;
    position: fixed;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 90%;
    z-index: 10001;
    border: 1px solid #535351;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}
#confirmBox .confirmBoxText{
    padding-bottom: 1.5rem;
}
#confirmBox .confirmBoxTextWrapper,
#confirmBox .confirmBoxTitle{
    padding: 1.5rem;
}
#confirmBox .confirmBoxTitle{
    background-color: #888886;
    color: #FFFFFF;
    font-weight: bold;
    letter-spacing: 1px;
}
#confirmBox .confirmButtons{
    text-align: center;
}
#confirmOverlay{
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
}
#confirmBox .confirmBoxTitle button{
    float: right;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
#closeModal img{
    width: 2rem;
    height: 2rem;
}

:root{
    /* pagination */
}
.pagination{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-width: 100%;
    position: relative;
    width: 100%;
}
.pagination a{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #F4F4F2;
    color: #333333;
    border: 1px solid #CDCDC8;
    border-right: none;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    height: 2.6rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: 1;
    min-height: 2.6rem;
    min-width: 2.6rem;
    position: relative;
    text-decoration: none;
}
.pagination a.paginationCurrent{
    background-color: #A9A9A7;
    border-color: #A9A9A7;
    color: #ffffff;
}
.pagination a.paginationFirst span,
.pagination a.paginationLast span,
.pagination a.paginationPrev span,
.pagination a.paginationNext span{
    left: -9999px;
    position: absolute;
    top: -9999px;
}
.pagination a.paginationFirst span,
.pagination a.paginationPrev span{
    padding: 0 0 0 0.8rem;
}
.pagination a.paginationFirst{
    background-color: #ffffff;
}
.pagination a.paginationLast{
    background-color: #ffffff;
}
.pagination a.paginationPrev{
    background-color: #ffffff;
}
.pagination a.paginationNext{
    background-color: #ffffff;
}
.pagination a.paginationFirst::before{
    content: "\f100";
}
.pagination a.paginationFirst::before,
.pagination a.paginationLast::after,
.pagination a.paginationPrev::before,
.pagination a.paginationNext::after{
    display: inline-block;
    font-family: "FontAwesome", sans-serif;
    font-size: inherit;
    text-rendering: auto;
}
.pagination a.paginationLast span,
.pagination a.paginationNext span{
    padding: 0 0.5rem 0 0;
}
.pagination a.paginationLast::after{
    content: "\f101";
}
.pagination a.paginationNext::after{
    content: "\f105";
}
.pagination a.paginationPrev::before{
    content: "\f104";
}
.pagination a:first-child{
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}
.pagination a:last-child{
    border-right: 1px solid #CDCDC8;
}
.pagination a:last-child{
    border-bottom-right-radius: 4px;
    border-right: 1px solid #CDCDC8;
    border-top-right-radius: 4px;
}
.pagination a:not([class*="paginationCurrent"]):hover{
    background-color: #B8B8AF;
    border-color: #B8B8AF;
    color: #FFFFFF;
}
@media only screen and (min-width: 680px){
    .pagination a{
        height: 3.2rem;
        min-height: 3.2rem;
        min-width: 3.2rem;
    }
}

div.pp_default .pp_top,div.pp_default .pp_top .pp_middle,div.pp_default .pp_top .pp_left,div.pp_default .pp_top .pp_right,div.pp_default .pp_bottom,div.pp_default .pp_bottom .pp_left,div.pp_default .pp_bottom .pp_middle,div.pp_default .pp_bottom .pp_right{height:13px}
div.pp_default .pp_top .pp_left{background:url('/assets/graphics/prettyPhoto/default/sprite.png') -78px -93px no-repeat}
div.pp_default .pp_top .pp_middle{background:url('/assets/graphics/prettyPhoto/default/sprite_x.png') top left repeat-x}
div.pp_default .pp_top .pp_right{background:url('/assets/graphics/prettyPhoto/default/sprite.png') -112px -93px no-repeat}
div.pp_default .pp_content .ppt{color:#f8f8f8}
div.pp_default .pp_content_container .pp_left{background:url('/assets/graphics/prettyPhoto/default/sprite_y.png') -7px 0 repeat-y;padding-left:13px}
div.pp_default .pp_content_container .pp_right{background:url('/assets/graphics/prettyPhoto/default/sprite_y.png') top right repeat-y;padding-right:13px}
div.pp_default .pp_next:hover{background:url('/assets/graphics/prettyPhoto/default/sprite_next.png') center right no-repeat;cursor:pointer}
div.pp_default .pp_previous:hover{background:url('/assets/graphics/prettyPhoto/default/sprite_prev.png') center left no-repeat;cursor:pointer}
div.pp_default .pp_expand{background:url('/assets/graphics/prettyPhoto/default/sprite.png') 0 -29px no-repeat;cursor:pointer;width:28px;height:28px}
div.pp_default .pp_expand:hover{background:url('/assets/graphics/prettyPhoto/default/sprite.png') 0 -56px no-repeat;cursor:pointer}
div.pp_default .pp_contract{background:url('/assets/graphics/prettyPhoto/default/sprite.png') 0 -84px no-repeat;cursor:pointer;width:28px;height:28px}
div.pp_default .pp_contract:hover{background:url('/assets/graphics/prettyPhoto/default/sprite.png') 0 -113px no-repeat;cursor:pointer}
div.pp_default .pp_close{width:30px;height:30px;background:url('/assets/graphics/prettyPhoto/default/sprite.png') 2px 1px no-repeat;cursor:pointer}
div.pp_default .pp_gallery ul li a{background:url('/assets/graphics/prettyPhoto/default/default_thumb.png') center center #f8f8f8;border:1px solid #aaa}
div.pp_default .pp_social{margin-top:7px}
div.pp_default .pp_gallery a.pp_arrow_previous,div.pp_default .pp_gallery a.pp_arrow_next{position:static;left:auto}
div.pp_default .pp_nav .pp_play,div.pp_default .pp_nav .pp_pause{background:url('/assets/graphics/prettyPhoto/default/sprite.png') -51px 1px no-repeat;height:30px;width:30px}
div.pp_default .pp_nav .pp_pause{background-position:-51px -29px}
div.pp_default a.pp_arrow_previous,div.pp_default a.pp_arrow_next{background:url('/assets/graphics/prettyPhoto/default/sprite.png') -31px -3px no-repeat;height:20px;width:20px;margin:4px 0 0}
div.pp_default a.pp_arrow_next{left:52px;background-position:-82px -3px}
div.pp_default .pp_content_container .pp_details{margin-top:5px}
div.pp_default .pp_nav{clear:none;height:30px;width:110px;position:relative}
div.pp_default .pp_nav .currentTextHolder{font-family:Georgia;font-style:italic;color:#999;font-size:11px;left:75px;line-height:25px;position:absolute;top:2px;margin:0;padding:0 0 0 10px}
div.pp_default .pp_close:hover,div.pp_default .pp_nav .pp_play:hover,div.pp_default .pp_nav .pp_pause:hover,div.pp_default .pp_arrow_next:hover,div.pp_default .pp_arrow_previous:hover{opacity:0.7}
div.pp_default .pp_description{font-size:11px;font-weight:700;line-height:14px;margin:5px 50px 5px 0}
div.pp_default .pp_bottom .pp_left{background:url('/assets/graphics/prettyPhoto/default/sprite.png') -78px -127px no-repeat}
div.pp_default .pp_bottom .pp_middle{background:url('/assets/graphics/prettyPhoto/default/sprite_x.png') bottom left repeat-x}
div.pp_default .pp_bottom .pp_right{background:url('/assets/graphics/prettyPhoto/default/sprite.png') -112px -127px no-repeat}
div.pp_default .pp_loaderIcon{background:url('/assets/graphics/prettyPhoto/default/loader.gif') center center no-repeat}
div.light_rounded .pp_top .pp_left{background:url('/assets/graphics/prettyPhoto/light_rounded/sprite.png') -88px -53px no-repeat}
div.light_rounded .pp_top .pp_right{background:url('/assets/graphics/prettyPhoto/light_rounded/sprite.png') -110px -53px no-repeat}
div.light_rounded .pp_next:hover{background:url('/assets/graphics/prettyPhoto/light_rounded/btnNext.png') center right no-repeat;cursor:pointer}
div.light_rounded .pp_previous:hover{background:url('/assets/graphics/prettyPhoto/light_rounded/btnPrevious.png') center left no-repeat;cursor:pointer}
div.light_rounded .pp_expand{background:url('/assets/graphics/prettyPhoto/light_rounded/sprite.png') -31px -26px no-repeat;cursor:pointer}
div.light_rounded .pp_expand:hover{background:url('/assets/graphics/prettyPhoto/light_rounded/sprite.png') -31px -47px no-repeat;cursor:pointer}
div.light_rounded .pp_contract{background:url('/assets/graphics/prettyPhoto/light_rounded/sprite.png') 0 -26px no-repeat;cursor:pointer}
div.light_rounded .pp_contract:hover{background:url('/assets/graphics/prettyPhoto/light_rounded/sprite.png') 0 -47px no-repeat;cursor:pointer}
div.light_rounded .pp_close{width:75px;height:22px;background:url('/assets/graphics/prettyPhoto/light_rounded/sprite.png') -1px -1px no-repeat;cursor:pointer}
div.light_rounded .pp_nav .pp_play{background:url('/assets/graphics/prettyPhoto/light_rounded/sprite.png') -1px -100px no-repeat;height:15px;width:14px}
div.light_rounded .pp_nav .pp_pause{background:url('/assets/graphics/prettyPhoto/light_rounded/sprite.png') -24px -100px no-repeat;height:15px;width:14px}
div.light_rounded .pp_arrow_previous{background:url('/assets/graphics/prettyPhoto/light_rounded/sprite.png') 0 -71px no-repeat}
div.light_rounded .pp_arrow_next{background:url('/assets/graphics/prettyPhoto/light_rounded/sprite.png') -22px -71px no-repeat}
div.light_rounded .pp_bottom .pp_left{background:url('/assets/graphics/prettyPhoto/light_rounded/sprite.png') -88px -80px no-repeat}
div.light_rounded .pp_bottom .pp_right{background:url('/assets/graphics/prettyPhoto/light_rounded/sprite.png') -110px -80px no-repeat}
div.dark_rounded .pp_top .pp_left{background:url('/assets/graphics/prettyPhoto/dark_rounded/sprite.png') -88px -53px no-repeat}
div.dark_rounded .pp_top .pp_right{background:url('/assets/graphics/prettyPhoto/dark_rounded/sprite.png') -110px -53px no-repeat}
div.dark_rounded .pp_content_container .pp_left{background:url('/assets/graphics/prettyPhoto/dark_rounded/contentPattern.png') top left repeat-y}
div.dark_rounded .pp_content_container .pp_right{background:url('/assets/graphics/prettyPhoto/dark_rounded/contentPattern.png') top right repeat-y}
div.dark_rounded .pp_next:hover{background:url('/assets/graphics/prettyPhoto/dark_rounded/btnNext.png') center right no-repeat;cursor:pointer}
div.dark_rounded .pp_previous:hover{background:url('/assets/graphics/prettyPhoto/dark_rounded/btnPrevious.png') center left no-repeat;cursor:pointer}
div.dark_rounded .pp_expand{background:url('/assets/graphics/prettyPhoto/dark_rounded/sprite.png') -31px -26px no-repeat;cursor:pointer}
div.dark_rounded .pp_expand:hover{background:url('/assets/graphics/prettyPhoto/dark_rounded/sprite.png') -31px -47px no-repeat;cursor:pointer}
div.dark_rounded .pp_contract{background:url('/assets/graphics/prettyPhoto/dark_rounded/sprite.png') 0 -26px no-repeat;cursor:pointer}
div.dark_rounded .pp_contract:hover{background:url('/assets/graphics/prettyPhoto/dark_rounded/sprite.png') 0 -47px no-repeat;cursor:pointer}
div.dark_rounded .pp_close{width:75px;height:22px;background:url('/assets/graphics/prettyPhoto/dark_rounded/sprite.png') -1px -1px no-repeat;cursor:pointer}
div.dark_rounded .pp_description{margin-right:85px;color:#fff}
div.dark_rounded .pp_nav .pp_play{background:url('/assets/graphics/prettyPhoto/dark_rounded/sprite.png') -1px -100px no-repeat;height:15px;width:14px}
div.dark_rounded .pp_nav .pp_pause{background:url('/assets/graphics/prettyPhoto/dark_rounded/sprite.png') -24px -100px no-repeat;height:15px;width:14px}
div.dark_rounded .pp_arrow_previous{background:url('/assets/graphics/prettyPhoto/dark_rounded/sprite.png') 0 -71px no-repeat}
div.dark_rounded .pp_arrow_next{background:url('/assets/graphics/prettyPhoto/dark_rounded/sprite.png') -22px -71px no-repeat}
div.dark_rounded .pp_bottom .pp_left{background:url('/assets/graphics/prettyPhoto/dark_rounded/sprite.png') -88px -80px no-repeat}
div.dark_rounded .pp_bottom .pp_right{background:url('/assets/graphics/prettyPhoto/dark_rounded/sprite.png') -110px -80px no-repeat}
div.dark_rounded .pp_loaderIcon{background:url('/assets/graphics/prettyPhoto/dark_rounded/loader.gif') center center no-repeat}
div.dark_square .pp_left,div.dark_square .pp_middle,div.dark_square .pp_right,div.dark_square .pp_content{background:#000}
div.dark_square .pp_description{color:#fff;margin:0 85px 0 0}
div.dark_square .pp_loaderIcon{background:url('/assets/graphics/prettyPhoto/dark_square/loader.gif') center center no-repeat}
div.dark_square .pp_expand{background:url('/assets/graphics/prettyPhoto/dark_square/sprite.png') -31px -26px no-repeat;cursor:pointer}
div.dark_square .pp_expand:hover{background:url('/assets/graphics/prettyPhoto/dark_square/sprite.png') -31px -47px no-repeat;cursor:pointer}
div.dark_square .pp_contract{background:url('/assets/graphics/prettyPhoto/dark_square/sprite.png') 0 -26px no-repeat;cursor:pointer}
div.dark_square .pp_contract:hover{background:url('/assets/graphics/prettyPhoto/dark_square/sprite.png') 0 -47px no-repeat;cursor:pointer}
div.dark_square .pp_close{width:75px;height:22px;background:url('/assets/graphics/prettyPhoto/dark_square/sprite.png') -1px -1px no-repeat;cursor:pointer}
div.dark_square .pp_nav{clear:none}
div.dark_square .pp_nav .pp_play{background:url('/assets/graphics/prettyPhoto/dark_square/sprite.png') -1px -100px no-repeat;height:15px;width:14px}
div.dark_square .pp_nav .pp_pause{background:url('/assets/graphics/prettyPhoto/dark_square/sprite.png') -24px -100px no-repeat;height:15px;width:14px}
div.dark_square .pp_arrow_previous{background:url('/assets/graphics/prettyPhoto/dark_square/sprite.png') 0 -71px no-repeat}
div.dark_square .pp_arrow_next{background:url('/assets/graphics/prettyPhoto/dark_square/sprite.png') -22px -71px no-repeat}
div.dark_square .pp_next:hover{background:url('/assets/graphics/prettyPhoto/dark_square/btnNext.png') center right no-repeat;cursor:pointer}
div.dark_square .pp_previous:hover{background:url('/assets/graphics/prettyPhoto/dark_square/btnPrevious.png') center left no-repeat;cursor:pointer}
div.light_square .pp_expand{background:url('/assets/graphics/prettyPhoto/light_square/sprite.png') -31px -26px no-repeat;cursor:pointer}
div.light_square .pp_expand:hover{background:url('/assets/graphics/prettyPhoto/light_square/sprite.png') -31px -47px no-repeat;cursor:pointer}
div.light_square .pp_contract{background:url('/assets/graphics/prettyPhoto/light_square/sprite.png') 0 -26px no-repeat;cursor:pointer}
div.light_square .pp_contract:hover{background:url('/assets/graphics/prettyPhoto/light_square/sprite.png') 0 -47px no-repeat;cursor:pointer}
div.light_square .pp_close{width:75px;height:22px;background:url('/assets/graphics/prettyPhoto/light_square/sprite.png') -1px -1px no-repeat;cursor:pointer}
div.light_square .pp_nav .pp_play{background:url('/assets/graphics/prettyPhoto/light_square/sprite.png') -1px -100px no-repeat;height:15px;width:14px}
div.light_square .pp_nav .pp_pause{background:url('/assets/graphics/prettyPhoto/light_square/sprite.png') -24px -100px no-repeat;height:15px;width:14px}
div.light_square .pp_arrow_previous{background:url('/assets/graphics/prettyPhoto/light_square/sprite.png') 0 -71px no-repeat}
div.light_square .pp_arrow_next{background:url('/assets/graphics/prettyPhoto/light_square/sprite.png') -22px -71px no-repeat}
div.light_square .pp_next:hover{background:url('/assets/graphics/prettyPhoto/light_square/btnNext.png') center right no-repeat;cursor:pointer}
div.light_square .pp_previous:hover{background:url('/assets/graphics/prettyPhoto/light_square/btnPrevious.png') center left no-repeat;cursor:pointer}
div.facebook .pp_top .pp_left{background:url('/assets/graphics/prettyPhoto/facebook/sprite.png') -88px -53px no-repeat}
div.facebook .pp_top .pp_middle{background:url('/assets/graphics/prettyPhoto/facebook/contentPatternTop.png') top left repeat-x}
div.facebook .pp_top .pp_right{background:url('/assets/graphics/prettyPhoto/facebook/sprite.png') -110px -53px no-repeat}
div.facebook .pp_content_container .pp_left{background:url('/assets/graphics/prettyPhoto/facebook/contentPatternLeft.png') top left repeat-y}
div.facebook .pp_content_container .pp_right{background:url('/assets/graphics/prettyPhoto/facebook/contentPatternRight.png') top right repeat-y}
div.facebook .pp_expand{background:url('/assets/graphics/prettyPhoto/facebook/sprite.png') -31px -26px no-repeat;cursor:pointer}
div.facebook .pp_expand:hover{background:url('/assets/graphics/prettyPhoto/facebook/sprite.png') -31px -47px no-repeat;cursor:pointer}
div.facebook .pp_contract{background:url('/assets/graphics/prettyPhoto/facebook/sprite.png') 0 -26px no-repeat;cursor:pointer}
div.facebook .pp_contract:hover{background:url('/assets/graphics/prettyPhoto/facebook/sprite.png') 0 -47px no-repeat;cursor:pointer}
div.facebook .pp_close{width:22px;height:22px;background:url('/assets/graphics/prettyPhoto/facebook/sprite.png') -1px -1px no-repeat;cursor:pointer}
div.facebook .pp_description{margin:0 37px 0 0}
div.facebook .pp_loaderIcon{background:url('/assets/graphics/prettyPhoto/facebook/loader.gif') center center no-repeat}
div.facebook .pp_arrow_previous{background:url('/assets/graphics/prettyPhoto/facebook/sprite.png') 0 -71px no-repeat;height:22px;margin-top:0;width:22px}
div.facebook .pp_arrow_previous.disabled{background-position:0 -96px;cursor:default}
div.facebook .pp_arrow_next{background:url('/assets/graphics/prettyPhoto/facebook/sprite.png') -32px -71px no-repeat;height:22px;margin-top:0;width:22px}
div.facebook .pp_arrow_next.disabled{background-position:-32px -96px;cursor:default}
div.facebook .pp_nav{margin-top:0}
div.facebook .pp_nav p{font-size:15px;padding:0 3px 0 4px}
div.facebook .pp_nav .pp_play{background:url('/assets/graphics/prettyPhoto/facebook/sprite.png') -1px -123px no-repeat;height:22px;width:22px}
div.facebook .pp_nav .pp_pause{background:url('/assets/graphics/prettyPhoto/facebook/sprite.png') -32px -123px no-repeat;height:22px;width:22px}
div.facebook .pp_next:hover{background:url('/assets/graphics/prettyPhoto/facebook/btnNext.png') center right no-repeat;cursor:pointer}
div.facebook .pp_previous:hover{background:url('/assets/graphics/prettyPhoto/facebook/btnPrevious.png') center left no-repeat;cursor:pointer}
div.facebook .pp_bottom .pp_left{background:url('/assets/graphics/prettyPhoto/facebook/sprite.png') -88px -80px no-repeat}
div.facebook .pp_bottom .pp_middle{background:url('/assets/graphics/prettyPhoto/facebook/contentPatternBottom.png') top left repeat-x}
div.facebook .pp_bottom .pp_right{background:url('/assets/graphics/prettyPhoto/facebook/sprite.png') -110px -80px no-repeat}
div.pp_pic_holder a:focus{outline:none}
div.pp_overlay{background:#000;display:none;left:0;position:absolute;top:0;width:100%;z-index:9500}
div.pp_pic_holder{display:none;position:absolute;width:100px;z-index:10000}
.pp_content{height:40px;min-width:40px}
* html .pp_content{width:40px}
.pp_content_container{position:relative;text-align:left;width:100%}
.pp_content_container .pp_left{padding-left:20px}
.pp_content_container .pp_right{padding-right:20px}
.pp_content_container .pp_details{float:left;margin:10px 0 2px}
.pp_description{display:none;margin:0}
.pp_social{float:left;margin:0}
.pp_social .facebook{float:left;margin-left:5px;width:55px;overflow:hidden}
.pp_social .twitter{float:left}
.pp_nav{clear:right;float:left;margin:3px 10px 0 0}
.pp_nav p{float:left;white-space:nowrap;margin:2px 4px}
.pp_nav .pp_play,.pp_nav .pp_pause{float:left;margin-right:4px;text-indent:-10000px}
a.pp_arrow_previous,a.pp_arrow_next{display:block;float:left;height:15px;margin-top:3px;overflow:hidden;text-indent:-10000px;width:14px}
.pp_hoverContainer{position:absolute;top:0;width:100%;z-index:2000}
.pp_gallery{display:none;left:50%;margin-top:-50px;position:absolute;z-index:10000}
.pp_gallery div{float:left;overflow:hidden;position:relative}
.pp_gallery ul{float:left;height:35px;position:relative;white-space:nowrap;margin:0 0 0 5px;padding:0}
.pp_gallery ul a{border:1px rgba(0,0,0,0.5) solid;display:block;float:left;height:33px;overflow:hidden}
.pp_gallery ul a img{border:0}
.pp_gallery li{display:block;float:left;margin:0 5px 0 0;padding:0}
.pp_gallery li.default a{background:url('/assets/graphics/prettyPhoto/facebook/default_thumbnail.gif') 0 0 no-repeat;display:block;height:33px;width:50px}
.pp_gallery .pp_arrow_previous,.pp_gallery .pp_arrow_next{margin-top:7px!important}
a.pp_next{background:url('/assets/graphics/prettyPhoto/light_rounded/btnNext.png') 10000px 10000px no-repeat;display:block;float:right;height:100%;text-indent:-10000px;width:49%}
a.pp_previous{background:url('/assets/graphics/prettyPhoto/light_rounded/btnNext.png') 10000px 10000px no-repeat;display:block;float:left;height:100%;text-indent:-10000px;width:49%}
a.pp_expand,a.pp_contract{cursor:pointer;display:none;height:20px;position:absolute;right:30px;text-indent:-10000px;top:10px;width:20px;z-index:20000}
a.pp_close{position:absolute;right:0;top:0;display:block;line-height:22px;text-indent:-10000px}
.pp_loaderIcon{display:block;height:24px;left:50%;position:absolute;top:50%;width:24px;margin:-12px 0 0 -12px}
#pp_full_res{line-height:1!important}
#pp_full_res .pp_inline{text-align:left}
#pp_full_res .pp_inline p{margin:0 0 15px}
div.ppt{color:#fff;display:none;font-size:17px;z-index:9999;margin:0 0 5px 15px}
div.pp_default .pp_content,div.light_rounded .pp_content{background-color:#fff}
div.pp_default #pp_full_res .pp_inline,div.light_rounded .pp_content .ppt,div.light_rounded #pp_full_res .pp_inline,div.light_square .pp_content .ppt,div.light_square #pp_full_res .pp_inline,div.facebook .pp_content .ppt,div.facebook #pp_full_res .pp_inline{color:#000}
div.pp_default .pp_gallery ul li a:hover,div.pp_default .pp_gallery ul li.selected a,.pp_gallery ul a:hover,.pp_gallery li.selected a{border-color:#fff}
div.pp_default .pp_details,div.light_rounded .pp_details,div.dark_rounded .pp_details,div.dark_square .pp_details,div.light_square .pp_details,div.facebook .pp_details{position:relative}
div.light_rounded .pp_top .pp_middle,div.light_rounded .pp_content_container .pp_left,div.light_rounded .pp_content_container .pp_right,div.light_rounded .pp_bottom .pp_middle,div.light_square .pp_left,div.light_square .pp_middle,div.light_square .pp_right,div.light_square .pp_content,div.facebook .pp_content{background:#fff}
div.light_rounded .pp_description,div.light_square .pp_description{margin-right:85px}
div.light_rounded .pp_gallery a.pp_arrow_previous,div.light_rounded .pp_gallery a.pp_arrow_next,div.dark_rounded .pp_gallery a.pp_arrow_previous,div.dark_rounded .pp_gallery a.pp_arrow_next,div.dark_square .pp_gallery a.pp_arrow_previous,div.dark_square .pp_gallery a.pp_arrow_next,div.light_square .pp_gallery a.pp_arrow_previous,div.light_square .pp_gallery a.pp_arrow_next{margin-top:12px!important}
div.light_rounded .pp_arrow_previous.disabled,div.dark_rounded .pp_arrow_previous.disabled,div.dark_square .pp_arrow_previous.disabled,div.light_square .pp_arrow_previous.disabled{background-position:0 -87px;cursor:default}
div.light_rounded .pp_arrow_next.disabled,div.dark_rounded .pp_arrow_next.disabled,div.dark_square .pp_arrow_next.disabled,div.light_square .pp_arrow_next.disabled{background-position:-22px -87px;cursor:default}
div.light_rounded .pp_loaderIcon,div.light_square .pp_loaderIcon{background:url('/assets/graphics/prettyPhoto/light_rounded/loader.gif') center center no-repeat}
div.dark_rounded .pp_top .pp_middle,div.dark_rounded .pp_content,div.dark_rounded .pp_bottom .pp_middle{background:url('/assets/graphics/prettyPhoto/dark_rounded/contentPattern.png') top left repeat}
div.dark_rounded .currentTextHolder,div.dark_square .currentTextHolder{color:#c4c4c4}
div.dark_rounded #pp_full_res .pp_inline,div.dark_square #pp_full_res .pp_inline{color:#fff}
.pp_top,.pp_bottom{height:20px;position:relative}
* html .pp_top,* html .pp_bottom{padding:0 20px}
.pp_top .pp_left,.pp_bottom .pp_left{height:20px;left:0;position:absolute;width:20px}
.pp_top .pp_middle,.pp_bottom .pp_middle{height:20px;left:20px;position:absolute;right:20px}
* html .pp_top .pp_middle,* html .pp_bottom .pp_middle{left:0;position:static}
.pp_top .pp_right,.pp_bottom .pp_right{height:20px;left:auto;position:absolute;right:0;top:0;width:20px}
.pp_fade,.pp_gallery li.default a img{display:none}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
:root{
}
.secondaryNavMenu,
.secondaryNavMenu ul{
    font-size: 1.6rem;
    list-style: none;
    margin: 0;
    padding: 0;
}
.secondaryNavMenu::after{
    clear: both;
}
.secondaryNavMenu::before,
.secondaryNavMenu::after{
    content: "";
    display: table;
}
.secondaryNavMenu li{
    width: 100%;
}
.secondaryNavMenu li.here > a:first-child,
.secondaryNavMenu li.parent-here > a:first-child{
    font-weight: bold;
}
.secondaryNavMenu a{
    color: #333333;
    display: block;
    line-height: normal;
    padding: 1rem 2rem 1rem 2rem;
    text-decoration: none;
    width: 100%;
}
.secondaryNavMenu a:hover{
    color: #333333;
}
.secondaryNavMenu ul a{
    padding: 1rem 2rem 1rem 3rem;
}
.secondaryNavMenu ul ul a{
    padding: 1rem 2rem 1rem 4rem;
}





:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
:root{
}
.simpleSearchForm{
    display: block;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    border: none;
}
.simpleSearchForm .shownFields{
    background: #FFFFFF;
    width: 100%;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    position: relative;
    overflow: hidden;
}
.simpleSearchForm .shownFields > *{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.simpleSearchForm button{
    background: #F4F4F2;
    border: 1px solid #B3B3B1;
    border-left: none;
    color: #5D5D5B;
    cursor: pointer;
    font: inherit;
    font-size: 80%;
    line-height: 1;
    margin: 0;
    text-align: center;
    text-indent: -9999px;
    width: 20%;
    min-width: 3rem;
    max-width: 3.5rem;
    position: relative;
}
.simpleSearchForm button:hover{
    background: #C9C9C7;
    color: #FFFFFF;
}
.simpleSearchForm button:hover::after{
    border-color: rgba(0, 0, 0, 0) #ffffff;
}
.simpleSearchForm button::after{
    border-color: rgba(0, 0, 0, 0) #B3B3B1;
    border-style: solid;
    border-width: 5px 0 5px 10px;
    content: "";
    display: block;
    height: 0;
    left: calc(50% - 5px);
    position: absolute;
    top: calc(50% - 5px);
    width: 0;
}
.simpleSearchForm input{
    background: transparent;
    border: 1px solid #B3B3B1;
    color: #5D5D5B;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.5rem;
    line-height: 1;
    margin: 0;
    padding: 0.85rem 1rem 0.85rem 1rem;
    width: 100%;
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
:root{
}
.slide{
    display: none;
    position: relative;
    vertical-align: middle;
    width: 100%;
}
.slide img{
    height: auto;
    max-height: none;
    max-width: none;
    min-width: 100%;
    position: relative;
    width: 100%;
    display: block;
}
.slide:first-child{
    display: block;
}
.slideCopy{
    padding-bottom: 1.5rem;
}
.slideHeadline{
    font-size: 2.4rem;
    margin: 0 0 1.5rem 0;
}
.slides{
    position: relative;
    width: 100%;
}
.slides a{
    text-decoration: none;
}
.slidesDots{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
    bottom: 1rem;
    width: 100%;
    z-index: 1000;
}
.slidesDots span{
    background: #ffffff;
    border: 1px solid #afafaf;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 0.9rem;
    margin: 0.3rem;
    text-indent: -9999px;
    width: 0.9rem;
}
.slidesDots span.cycle-pager-active{
    background: #afafaf;
    border: 1px solid #afafaf;
}
.slidesNav{
    display: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: absolute;
    top: calc(50% - 24px);
}
.slidesNav > div{
    background-color: #FFFFFF;
    cursor: pointer;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.slidesNav > div > *{
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
}
.slidesNav > div:hover{
    color: #484846;
}
.slidesNav > div:first-child{
    padding-right: 3px;
}
.slidesNav > div:last-child{
    padding-left: 3px;
}
.slidesWrapper{
    position: relative;
}
.slidesWrapper ul,
.slidesWrapper li{
    margin: 0;
    padding: 0;
}
@media only screen and (min-width: 800px){
    .slidesNav{
        -js-display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        left: -24px;
        right: -24px;
    }
}
.responsiveSlides_nav:active{
    opacity: 1.0;
}
.responsiveSlides_nav.next{
    left: auto;
    background-position: right top;
    right: 0;
}
.responsiveSlides_nav{
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    opacity: 0;
    filter: alpha(opacity=1);
    width: 48%;
    text-indent: -9999px;
    overflow: hidden;
    height: calc(100% - 2.8rem);
}
.responsiveSlides_nav.next{
    left: auto;
    right: 0;
}
.responsiveSlides_nav:active{
    opacity: 1.0;
}
.responsiveSlides_nav.next{
    left: auto;
    background-position: right 50%;
    right: 0;
}
.responsiveSlides_nav:focus{
    outline: none;
}
.responsiveSlides_tabs{
    margin-top: 1rem;
    text-align: center;
}
.responsiveSlides_tabs li{
    display: inline;
    float: none;
    margin-right: 5px;
}
.responsiveSlides_tabs a{
    text-indent: -9999px;
    overflow: hidden;
    border-radius: 50%;
    background: rgba(0, 0, 0, .2);
    display: inline-block;
    -webkit-box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, .3);
            box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, .3);
    width: 9px;
    height: 9px;
}
.responsiveSlides_here a{
    background: rgba(0, 0, 0, .8);
}


:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
:root{
}
/* UL */
.utilityNav,
.utilityNav ul{
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}
.utilityNav::after,
.utilityNav ul::after{
    clear: both;
}
.utilityNav::before,
.utilityNav::after,
.utilityNav ul::before,
.utilityNav ul::after{
    content: "";
    display: table;
}
/* LI */
.utilityNav li{
    cursor: pointer;
    display: inline-block;
    padding: 0 0.5rem 0 0.5rem;
    position: relative;
    width: auto;
}
.utilityNav li.separator{
    padding: 0;
}
.utilityNav li:hover ul{
    display: block;
}
/* A */
.utilityNav a{
    display: block;
    text-decoration: none;
    width: 100%;
}
.utilityNav a i::before{
    padding: 0 0.5rem 0 0.5rem;
}
/* SPAN */
.utilityNav span{
    display: block;
}
/* SUB UL */
.utilityNav ul{
    background-color: #ffffff;
    border: 1px solid #CCCCCC;
    border-radius: 2px;
    display: none;
    position: absolute;
    top: auto;
    z-index: 200;
    white-space: nowrap;
}
.utilityNav ul li{
    width: 100%;
    display: block;
}
