:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
.desktopSearchForm{
    display: none;
}
.ratioWrapper{
    position: relative;
}
.ratioWrapper > *{
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.ratioWrapper::after{
    clear: both;
    content: "";
    display: table;
}
.ratioWrapper::before{
    content: "";
    display: block;
    float: left;
    min-width: 100%;
    padding-top: 100%;
    width: 100%;
}
.ratioWrapper.ratio50::before{
    padding-top: 50%;
}
.ratioWrapper.ratio60::before{
    padding-top: 60%;
}
.ratioWrapper.ratio70::before{
    padding-top: 70%;
}
.ratioWrapper.ratio80::before{
    padding-top: 80%;
}
.ratioWrapper.ratio35mm::before{
    padding-top: 66.6666%;
}
.ratioWrapper.ratio35mmVert::before{
    padding-top: 150%;
}
.ratioWrapper.ratio2-1::before{
    padding-top: 50%;
}
.ratioWrapper.ratio35mmVert::before{
    padding-top: 150%;
}
@media only screen and (min-width: 800px){
    .desktopSearchForm{
        display: block;
    }
}


:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
:root{
}
/*
//////////////// skip menu //////////////////
*/
.skipNavigation{
    height: 1px;
    left: -10000px;
    overflow: hidden;
    position: absolute;
    text-align: left;
    width: 1px;
}
.skipNavigation:focus,
.skipNavigation:active{
    height: auto;
    left: 0;
    overflow: visible;
    position: static;
    text-decoration: underline;
    width: auto;
}
/*
//////////////// desktop menu //////////////////
*/
.desktopMainMenu{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;
}
.desktopMainMenu > li{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    position: relative;
    width: auto;
}
.desktopMainMenu > li > a{
    font-size: 1.6rem;
}
.desktopMainMenu > li.parent-here > a,
.desktopMainMenu > li.here > a,
.desktopMainMenu > li:hover > a{
}
.desktopMainMenu > li:hover ul{
    display: block;
}
.desktopMainMenu a{
    border: none;
    display: inline-block;
    text-decoration: none;
    width: 100%;
    font-size: 1.4rem;
    line-height: 1.2;
}
.desktopMainMenu ul{
    background-color: #FFFFFF;
    display: none;
    left: 1px;
    list-style: none;
    margin: 0;
    position: absolute;
}
.desktopMainMenu ul li a{
    border: none;
}
.desktopMainMenu::after{
    clear: both;
}
.desktopMainMenu::before,
.desktopMainMenu::after{
    content: "";
    display: table;
}
/*
//////////////// mobile menu toggle //////////////////
*/
.menuToggle{
    background: #434B6B;
    border-radius: 2px;
    cursor: pointer;
    display: block;
    height: 40px;
    position: fixed;
    right: 20px;
    top: 5px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    width: 40px;
    z-index: 1001;
}
.menuToggle .bar{
    background: #ffffff;
    display: block;
    height: 3px;
    margin: calc(50% - 1px) auto 0 auto;
    position: relative;
    text-indent: -9999px;
    -webkit-transition: background 0.3s ease-in-out;
    -o-transition: background 0.3s ease-in-out;
    transition: background 0.3s ease-in-out;
    width: 24px;
}
.menuToggle .bar::after{
    background: #ffffff;
    bottom: -8px;
    content: "";
    display: block;
    height: 3px;
    left: 0;
    position: absolute;
    -webkit-transform-origin: left bottom;
        -ms-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    width: 100%;
}
.menuToggle .bar::before{
    background: #ffffff;
    content: "";
    display: block;
    height: 3px;
    left: 0;
    position: absolute;
    top: -8px;
    -webkit-transform-origin: left top;
        -ms-transform-origin: left top;
            transform-origin: left top;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    width: 100%;
}
/*
//////////////// mobile menu //////////////////
*/
.mobileMainMenu{
    font-family: 'Source Sans Pro', Arial, "Helvetica Neue", Helvetica, sans-serif;
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
    position: relative;
    text-align: left;
    width: 100%;
}
.mobileMainMenu::after{
    clear: both;
}
.mobileMainMenu::before,
.mobileMainMenu::after{
    content: "";
    display: table;
}
.mobileMainMenu li{
    color: #FFFFFF;
    cursor: pointer;
    display: block;
    margin-top: 1px;
    padding: 0;
    position: relative;
    width: 100%;
}
/*.mobileMainMenu li{
    border-bottom: 1px solid var(--mmenu-li-border-color);
}
.mobileMainMenu li:last-child{
    border-bottom: none;
}
.mobileMainMenu ul li{
    border-bottom: 1px solid var(--mmenu-li2-border-color);
}
.mobileMainMenu ul li:last-child{
    border-bottom: none;
}
.mobileMainMenu ul ul li{
    border-bottom: 1px solid var(--mmenu-li3-border-color);
}
.mobileMainMenu ul ul li:last-child{
    border-bottom: none;
}*/
.mobileMainMenu a{
    color: #FFFFFF;
    background-color: rgb(73, 81, 116);
    cursor: pointer;
    display: block;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.4rem;
    line-height: 1.4;
    min-height: 30px;
    padding: 1.0rem 1.5rem 1.0rem 1.5rem;
    position: relative;
    text-align: left;
    text-decoration: none;
}
.mobileMainMenu a:hover{
    background-color: rgb(53, 59, 85);
    color: #FFFFFF;
}
.mobileMainMenu a:focus{
    background-color: rgb(53, 59, 85);
    color: #FFFFFF;
}
.mobileMainMenu a:active{
    background-color: rgb(53, 59, 85);
    color: #FFFFFF;
}
.mobileMainMenu ul a{
    background-color: rgb(81, 90, 129);
    padding-left: 25px;
}
.mobileMainMenu ul ul a{
    background-color: rgb(86, 97, 138);
    padding-left: 35px;
}
.mobileMainMenu ul a:hover{
    background-color: rgb(53, 59, 85);
}
.mobileMainMenu ul ul a:hover{
    background-color: rgb(53, 59, 85);
}
.mobileMainMenu li.hasChild > a{
    padding-right: 50px;
}
.mobileMainMenu .openSubMenu{
    background-color: rgba(114, 124, 167, 0.15);
    bottom: 0;
    color: #FFFFFF;
    cursor: pointer;
    display: block;
    line-height: 10px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 36px;
    z-index: 99999;
}
.mobileMainMenu .openSubMenu.subMenuOpen::after{
    content: "\25B2";
}
.mobileMainMenu .openSubMenu:hover{
    background-color: rgba(88, 99, 141, 0.1);
}
.mobileMainMenu .openSubMenu::after{
    content: "\25BC";
    display: block;
    font-size: 75%;
    left: calc(50% - 6px);
    margin: auto;
    position: absolute;
    top: calc(50% - 4px);
}
.mobileMainMenu ul{
    display: none;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
}
/*
//////////////// menu toggles //////////////////
*/
#closeMobileMenu{
    cursor: pointer;
    display: block;
    height: 100%;
    position: relative;
    text-indent: -9999px;
    width: 100%;
}
#desktopNavigation{
    display: none;
}
#menuPanelTrigger{
    display: none;
}
#menuPanelTrigger:checked ~ #pageInnerWrapper{
    height: 100%;
    overflow: hidden;
}
#menuPanelTrigger:checked ~ #pageInnerWrapper .menuToggle{
    right: 275px;
    border-radius: 50%;
}
#menuPanelTrigger:checked ~ #pageInnerWrapper .menuToggle .bar{
    background: transparent;
    height: 2px;
}
#menuPanelTrigger:checked ~ #pageInnerWrapper .menuToggle .bar::after{
    bottom: -11px;
    height: 2px;
    left: 2px;
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
    width: 31px;
}
#menuPanelTrigger:checked ~ #pageInnerWrapper .menuToggle .bar::before{
    height: 2px;
    left: 2px;
    top: -11px;
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
    width: 31px;
}
#menuPanelTrigger:checked ~ #pageInnerWrapper header #mobileMenuBox{
    right: 0;
}
#mobileMenuBox{
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    bottom: 0;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    position: fixed;
    right: -100%;
    top: 0;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    width: 100%;
    z-index: 999;
}
#mobileMenuBox #mobileMenuBoxLeft{
    background-color: rgba(0, 0, 0, 0.4);
    color: #FFFFFF;
    position: relative;
    width: calc(100% - 270px);
}
#mobileMenuBox #mobileMenuBoxRight{
    background-color: #434B6B;
    color: #FFFFFF;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    overflow-y: scroll;
    padding: 0;
    position: relative;
    -webkit-overflow-scrolling: touch;
    width: 270px;
}
@media only screen and (min-width: 1050px){
    .menuToggle,
    #mobileMenuBox{
        display: none;
    }
    #desktopNavigation{
        display: block;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
#pageOuterWrapper{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    min-height: 100vh;
    position: relative;
    width: 100%;
}
#pageInnerWrapper{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
}
.constrainedWidth{
    margin: 0 auto 0 auto;
    max-width: 1050px;
    padding: 0;
    position: relative;
    width: 100%;
}
header.banner{
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    position: relative;
    width: 100%;
    z-index: 2;
}
header.banner .bannerContents{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
header.banner .bannerContents > div{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
main#mainContent{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    position: relative;
    width: 100%;
    z-index: 1;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
main#mainContent > section{
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
}
main#mainContent > section:first-child{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
main#mainContent > section:first-child > div{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
footer.contentInfo{
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    position: relative;
    width: 100%;
}
footer.contentInfo .footerContents{
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
footer.contentInfo .footerContents > div{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.pageSectionContent{
    position: relative;
    width: 100%;
}
.pageSectionContentPrimary{
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    width: 100%;
}
.pageSectionContentLeft{
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    width: 100%;
}
.pageSectionContentRight{
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    width: 100%;
}
.pageContentPadder{
    padding: calc(1rem + 1.5%);
}
@media only screen and (min-width: 800px){
    .pageSectionContent{
        -js-display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row;
                flex-flow: row;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    }
    .pageSectionContentLeft{
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1;
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        max-width: calc(30rem + 4rem);
        min-width: calc(25rem + 4rem);
        width: 50%;
        padding: 0 0 3.0rem 0;
    }
    .pageSectionContentPrimary{
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
        padding: 0;
    }
    .pageSectionContentRight{
        -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
                order: 3;
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        max-width: calc(30rem + 4rem);
        min-width: calc(25rem + 4rem);
        width: 50%;
        padding: 0 0 3.0rem 0;
    }
    .pageSectionContentPrimary ~ .pageSectionContentLeft{
        padding-right: 4rem;
    }
    .pageSectionContentPrimary ~ .pageSectionContentRight{
        padding-left: 4rem;
    }
}
@media only screen and (min-width: 1050px){
    header.banner .bannerContents,
    footer.contentInfo .footerContents{
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }
}
@supports ((display: -ms-grid) or (display: grid)){
    #pageInnerWrapper{
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr;
            grid-template-columns: 1fr;
        -ms-grid-rows: minmax(1rem, auto) 1fr minmax(1rem, auto);
            grid-template-rows: minmax(1rem, auto) 1fr minmax(1rem, auto);
    }
    header.banner{
        -ms-grid-column: 1;
            grid-column-start: 1;
        -ms-grid-row: 1;
            grid-row-start: 1;
    }
    main#mainContent {
        -ms-grid-column: 1;
            grid-column-start: 1;
        -ms-grid-row: 2;
            grid-row-start: 2;
    }
    footer.contentInfo{
        -ms-grid-column: 1;
            grid-column-start: 1;
        -ms-grid-row: 3;
            grid-row-start: 3;
    }
}


:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
:root{
}
@media print{
    *{
        background: transparent !important;
        -webkit-box-shadow: none !important;
                box-shadow: none !important;
        text-shadow: none !important;
    }
    body{
        font-size: 1.4rem;
    }
    body,
    html,
    #pageOuterWrapper,
    #pageInnerWrapper{
        overflow: visible !important;
    }
    h1{
        font-size: 3rem;
    }
    h2{
        font-size: 2.5rem;
    }
    h2,
    h3,
    h4{
        page-break-after: avoid !important;
    }
    h3{
        font-size: 1.8rem;
    }
    h4,
    h5,
    h6{
        font-size: 1.5rem;
    }
    img{
        max-width: 100% !important;
    }
    p,
    h2,
    h3,
    h4{
        orphans: 3;
        widows: 3;
    }
    pre,
    blockquote{
        border: 1px solid #999999;
        -webkit-column-break-inside: avoid !important;
                break-inside: avoid !important;
    }
    thead{
        display: table-header-group;
    }
    tr,
    img{
        -webkit-column-break-inside: avoid !important;
                break-inside: avoid !important;
    }
    #desktopNavigation{
        display: block;
    }
    #mobileMenuBox,
    .menuToggle{
        display: none;
    }
    /********************************* SITE SPECIFIC BELOW ***********************************/
}
@page{
    margin: 0.5cm;
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
*,
*::before,
*::after{
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    outline: none;
    padding: 0;
    zoom: 1;
}
::-webkit-input-placeholder{
    color: #888888;
}
:-ms-input-placeholder{
    color: #888888;
}
::-ms-input-placeholder{
    color: #888888;
}
::placeholder{
    color: #888888;
}
:root{
    background: #ffffff;
    color: #333333;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-font-kerning: normal;
            font-kerning: normal;
    font-size: 62.5%;
    font-synthesis: none;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-variant-numeric: oldstyle-nums tabular-nums;
    /*font-variant-numeric: diagonal-fractions oldstyle-nums tabular-nums;*/
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
a{
    color: #003B71;
    /*    
    text-decoration-color: var(--link-underline-color);
    text-decoration-style: solid;
    text-decoration-skip: ink;
    */
}
p a{
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #00358d), color-stop(.13169rem, #00358d), color-stop(.13169rem, rgba(251, 250, 251, 0)), to(rgba(255, 124, 77, 0)));
    background-image: -o-linear-gradient(bottom, #00358d 0, #00358d .13169rem, rgba(251, 250, 251, 0) .13169rem, rgba(255, 124, 77, 0) 100%);
    background-image: linear-gradient(to top, #00358d 0, #00358d .13169rem, rgba(251, 250, 251, 0) .13169rem, rgba(255, 124, 77, 0) 100%);
    text-decoration: none;
}
a:hover{
    color: #003B71;
    cursor: pointer;
}
/*a:focus{
    color: var(--link-focus-color);
    outline: 1px solid;
    outline-offset: -1px;
}*/
a image{
    border: none;
    outline: none;
}
a.inheritColor,
a.inheritColor:hover,
a.inheritColor:focus,
a.inheritColor:active{
    color: inherit;
}
a.noUnderline{
    text-decoration: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary{
    display: block;
    position: relative;
}
audio,
canvas,
video{
    display: inline-block;
}
audio:not([controls]){
    display: none;
    height: 0;
}
blockquote{
    font-size: 150%;
    line-height: normal;
    margin: 3.0rem 1.5rem 3.0rem 1.5rem;
    padding: 0;
}
blockquote cite{
    display: block;
    font-size: 80%;
    margin: 1.5rem;
}
body{
    background: #ffffff none;
    margin: 0;
    overflow-y: scroll;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    position: relative;
}
dd{
    padding: 1rem;
}
fieldset{
    border: none;
    margin: 0 0 1.0rem 0;
    padding: 0;
}
fieldset legend{
    border-bottom: 1px solid #333333;
    color: #333333;
    font-weight: bold;
    margin: 0 0 1.0rem 0;
    width: 100%;
}
figure{
    margin: 0;
    padding: 0;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a{
    text-decoration: none;
}
h1,
h2,
h3,
h4,
h5{
    line-height: 1.1;
    margin: 0.5rem 0 0.7rem 0;
    width: 100%;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child{
    margin-top: 0;
}
hr{
    background: #888888;
    border: 0;
    color: #888888;
    height: 1px;
    margin: 1rem 0 1rem 0;
}
html,
body{
    height: 100%;
    overflow-x: hidden;
}
iframe{
    max-height: 100%;
    max-width: 100%;
}
img,
object{
    display: inline-block;
    height: auto;
    max-width: 100%;
}
object{
    pointer-events: none;
}
p{
    margin: 0 0 1.3rem 0;
}
p:last-child{
    margin: 0;
}
sup,
sub{
    font-size: 80%;
}
table{
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    width: 100%;
}
td{
    border: none;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    vertical-align: top;
}
th{
    border: none;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    text-align: left;
    vertical-align: bottom;
}
ul{
    list-style: disc;
}
ul ul,
ol ol{
    margin: 0 1rem 0 1rem;
    padding: 0 1rem 0 1rem;
}
ul,
ol{
    margin: 0.5rem 1rem 0.5rem 1rem;
    padding: 0 1rem 1rem 1rem;
    text-align: left;
}
input{
    color: #333331;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}
select{
    color: #333331;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}
textarea{
    color: #333331;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}
optgroup{
    color: #333331;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}
option{
    color: #333331;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}
input[disabled],
select[disabled],
textarea[disabled]{
    cursor: not-allowed;
}
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="file"]::-webkit-file-upload-button,
button{
    border-radius: 0;
}
input[type="radio"],
input[type="checkbox"]{
    display: inline-block;
    margin: 0 0.5rem 0 0.5rem;
}
input[type="text"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    margin: 0;
    padding: 0.5rem 1rem 0.5rem 1rem;
    width: 100%;
}
input[type="date"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    margin: 0;
    padding: 0.5rem 1rem 0.5rem 1rem;
    width: 100%;
}
input[type="range"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    margin: 0;
    padding: 0.5rem 1rem 0.5rem 1rem;
    width: 100%;
}
input[type="email"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    margin: 0;
    padding: 0.5rem 1rem 0.5rem 1rem;
    width: 100%;
}
input[type="url"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    margin: 0;
    padding: 0.5rem 1rem 0.5rem 1rem;
    width: 100%;
}
input[type="password"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    margin: 0;
    padding: 0.5rem 1rem 0.5rem 1rem;
    width: 100%;
}
input[type="tel"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    margin: 0;
    padding: 0.5rem 1rem 0.5rem 1rem;
    width: 100%;
}
input[type="number"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    margin: 0;
    padding: 0.5rem 1rem 0.5rem 1rem;
    width: 100%;
}
input[type="month"]{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    margin: 0;
    padding: 0.5rem 1rem 0.5rem 1rem;
    width: 100%;
}
textarea{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    display: inline-block;
    margin: 0;
    padding: 0.5rem 1rem 0.5rem 1rem;
    width: 100%;
}
select{
    border: 1px solid #B3B3B1;
    border-radius: 0;
    margin: 0;
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
:root{
}
body{
    color: #333333;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.4rem;
    line-height: 1.4;
}
body#wygwam{
    color: #333333;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.4rem;
    line-height: 1.4;
}
body.cke_ltr{
    color: #333333;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.4rem;
    line-height: 1.4;
}
h1{
    color: #333333;
    font-family: 'Source Sans Pro', Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 2.6rem;
    font-weight: normal;
}
h2{
    color: #333333;
    font-family: 'Source Sans Pro', Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 2rem;
    font-weight: normal;
}
h3{
    color: #333333;
    font-family: 'Source Sans Pro', Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.8rem;
    font-weight: normal;
}
h4{
    color: #333333;
    font-family: 'Source Sans Pro', Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
}
h4{
    line-height: 1.4;
}
h5{
    line-height: 1.4;
}
h5{
    color: #333333;
    font-family: 'Source Sans Pro', Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
}
/*
assumptions: default font size is 16px and is reset at the :root element to 62.5% which is 10px
locking breakpoints are converted to rems for use in the calculations e.g. 350px = 35rem
font-size: calc([minimum size font in rem] + (([maximum size font in rem without units] - [minimum size font in rem without units]) * (((100vw * .625) - [small breakpoint in rem]) / ([large breakpoint in rem without units] - [small breakpoint in rem without units]) )));
*/
@media screen and (min-width: 400px){
    body{
        font-size: calc(1.4rem + (((1.5 - 1.4) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
    body#wygwam{
        font-size: calc(1.4rem + (((1.5 - 1.4) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
    body.cke_ltr{
        font-size: calc(1.4rem + (((1.5 - 1.4) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
    h1{
        font-size: calc(2.6rem + (((3 - 2.6) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
    h2{
        font-size: calc(2rem + (((2.4 - 2) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
    h3{
        font-size: calc(1.8rem + (((2.0 - 1.8) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
    h4{
        font-size: calc(1.6rem + (((1.8 - 1.6) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
    h5{
        font-size: calc(1.6rem + (((1.6 - 1.6) * 10) * ((100vw - 400px) / (1050 - 400))));
    }
}
@media screen and (min-width: 1050px){
    body{
        font-size: 1.5rem;
    }
    body#wygwam{
        font-size: 1.5rem;
    }
    body.cke_ltr{
        font-size: 1.5rem;
    }
    h1{
        font-size: 3rem;
    }
    h2{
        font-size: 2.4rem;
    }
    h3{
        font-size: 2.0rem;
    }
    h4{
        font-size: 1.8rem;
    }
    h5{
        font-size: 1.6rem;
        margin-bottom: 0;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */
    
    /* site colors */

}
.floatLeft{
    float: left;
    margin: 0 3.0rem 0 0;
}
.floatRight{
    float: right;
    margin: 0 0 0 3.0rem;
}
.clearFix::after{
    clear: both;
}
.clearFix::before,
.clearFix::after{
    content: "";
    display: table;
}
.inheritColor{
    color: inherit;
}
.missingImage{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    margin: auto;
    max-width: 60%;
    opacity: 0.2;
}
.borderAll{
    border-style: solid;
    border-width: 1px;
}
.borderTop{
    border-top-style: solid;
    border-top-width: 1px;
}
.borderRight{
    border-right-style: solid;
    border-right-width: 1px;
}
.borderBottom{
    border-bottom-style: solid;
    border-bottom-width: 1px;
}
.borderLeft{
    border-left-style: solid;
    border-left-width: 1px;
}
.borderNone{
    border-style: none;
    border-width: 0;
}
.dim{
    opacity: 1;
    -webkit-transition: opacity .2s ease-in;
    -o-transition: opacity .2s ease-in;
    transition: opacity .2s ease-in;
}
.dim:hover,
.dim:focus{
    opacity: 0.5;
    -webkit-transition: opacity .2s ease-in;
    -o-transition: opacity .2s ease-in;
    transition: opacity .2s ease-in;
}
.letterJump{
    -ms-flex-line-pack: start;
        align-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 1.5rem;
    width: auto;
}
.letterJump a,
.letterJump span{
    display: inline-block;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    max-width: 35px;
    min-width: 25px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}
.letterJump a:hover{
    background-color: #333333;
    color: #FFFFFF;
}
.letterJump span{
    color: #CACAC1;
}
.titleSupporting{
    margin-bottom: 1.5rem;
}
.entryDate{
    font-weight: bold;
}
.byline{
    font-style: italic;
}
.alignSelfStart.alignSelfStart.alignSelfStart.alignSelfStart{
    -ms-flex-item-align: start;
        align-self: flex-start;
}
.alignSelfEnd.alignSelfEnd.alignSelfEnd.alignSelfEnd{
    -ms-flex-item-align: end;
        align-self: flex-end;
}
.alignSelfCenter.alignSelfCenter.alignSelfCenter.alignSelfCenter{
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
}
.hidden.hidden.hidden{
    display: none;
}
